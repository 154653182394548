import "./info.scss"
import React, { useState } from "react"
import langs from "../../common/services/languageService";
import { pageStates } from "../../common/consts/pageStates";

const { labels, validationMessages } = langs;

export default function InfoPage({ pageState }) {
  window.history.replaceState(null, "JTI B2B2C", "/");
  var label = "";

  switch (pageState) {
    case pageStates.registrationSuccess:
      label = labels.RegistrationSuccess();
      break;
    case pageStates.alreadyRegistred:
      label = labels.CustomerAlreadyRegistred();
      break;
    case pageStates.registrationTimeout:
      label = labels.CustomerRegistrationTimeout();
      break;
    case pageStates.wrongRegistrationUrl:
      label = labels.WrongRegistrationUrl();
      break;
    case pageStates.processCanceled:
      label = labels.ProcessCanceledBySeller();
      break;
    case pageStates.unknownError:
      label = validationMessages.UnknownErrorMessage();
      break;
    default:
      label = validationMessages.UnknownErrorMessage();
  }

  return (
    <div className="ploom-info">
      <div className="ploom-info__header">
        <p>{labels.PloomRegistrationHeader()}</p>
        <p className="bold">{labels.Ploom()}</p>
      </div>
      <div className="ploom-info__body">
        <div className="ploom-info__body--info" dangerouslySetInnerHTML={{ __html: label }} />
      </div>
    </div>
  )
}