import axios from "axios";

var customerUrl = window.config.customerUrl;
var clerkApiUrl = window.config.clerkApiUrl;

export const registerCustomer = (model) => {
  return axios.post(customerUrl + 'customer/register', model);
}

export const getConsents = () => {
  return axios.get(customerUrl + 'customer/consents');
}

export const verifyCustomer = (transactionId) => {
  return axios.post(customerUrl + 'customer/validate-transaction', { transactionId: transactionId });
}

export const getRegulationLink = (type, offerId) => {
  const offerIdParam = offerId ? `?offerId=${offerId}` : "";

  return `${clerkApiUrl}clerk/regulations/${type}${offerIdParam}`;
}
