import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.scss';
import PloomRegisterForm from './components/ploom-register-form/ploom-register-form';
import RegistrationSuccess from './components/registration-success/registration-success';
import NoOffer from './components/no-offer/no-offer';
import PloomRegisterCustomer from './components/ploom-register-customer/ploom-register-customer';
import InfoPage from './components/info/info';
import { pageStates } from './common/consts/pageStates';

function App() {
  const router = createBrowserRouter([
    {
      path: "/zabka/:externalId",
      element: <PloomRegisterForm />,
    },
    {
      path: "/konsument/:offerId",
      element: <PloomRegisterForm isLightApp={true} />,
    },
    {
      path: "/success",
      element: <RegistrationSuccess />,
    },
    {
      path: "/no-offer",
      element: <NoOffer />,
    },
    {
      path: "/konsument/tranzakcja/:transactionId",
      element: <PloomRegisterCustomer />,
    },
    {
      path: "/konsument/t/:transactionId",
      element: <PloomRegisterCustomer />,
    },
    {
      path: "/error",
      element: <InfoPage pageState={pageStates.unknownError}/>
    },
    {
      path: "*",
      element: <InfoPage pageState={pageStates.wrongRegistrationUrl}/>
    }
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
