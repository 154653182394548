import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Checkbox.module.scss';
import { useController } from 'react-hook-form';
import clsx from 'clsx';


const propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    control: PropTypes.any,
    rules: PropTypes.any,
};

const defaultProps = {};

function Checkbox({ id, name, control, rules }) {
    const { 
        field,
        fieldState,
    } = useController({
        name,
        control,
        rules
    });

    const [value, setValue] = useState(field.value);
    const [isFocused, setIsFocused] = useState(false);


    const onChange = useCallback((e) => {
        setValue(e.target.checked);
        field.onChange(e.target.checked);
    }, [field]);

    const onMouseUp = useCallback((e) => {
        setIsFocused(false);
    }, []);

    const onFocus = (e) => {
        setIsFocused(true);
    };

    const onBlur = (e) => {
        setIsFocused(false);
    };

    return (
        <div className={clsx(styles.container, value && styles.checked, isFocused && styles.focused, fieldState.error && styles.validationError)}>
            <div className={styles.checkbox}>
                <input
                    ref={field.ref}
                    className={styles.input}
                    type="checkbox"
                    id={id}
                    // checked={field.value}
                    onClick={onChange}
                    onMouseUp={onMouseUp}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                <div className={styles.box}>
                    <svg className={clsx(styles.checkMark)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                        <path className="tick" fill="none" strokeLinecap="round" strokeWidth="4" d="M4 10l5 5 9-9" />
                    </svg>
                </div>
            </div>
        </div>
    );
}

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default memo(Checkbox);
