/* eslint-disable import/no-anonymous-default-export*/
// eslint-disable import/no-anonymous-default-export
// File was autogenerated by tools/generateLabels.js script
import ResourceService from '../../services/resourceService';
const _ = (path) => () => ResourceService.get(path);

export default {
        AbortProcess: _('labels.AbortProcess'),
        Accept: _('labels.Accept'),
        AccountConfirmedMessage: _('labels.AccountConfirmedMessage'),
        AccountNotConfirmedMessage: _('labels.AccountNotConfirmedMessage'),
        ActualProgram: _('labels.ActualProgram'),
        AdministratorOfYourDataIsMessage: _('labels.AdministratorOfYourDataIsMessage'),
        ApartmentNumber: _('labels.ApartmentNumber'),
        AvfaliableOffers: _('labels.AvfaliableOffers'),
        Back: _('labels.Back'),
        CancelCustomerRegistrationProcessQuestion: _('labels.CancelCustomerRegistrationProcessQuestion'),
        ChangePassword: _('labels.ChangePassword'),
        ChangePasswordSuccessInfo: _('labels.ChangePasswordSuccessInfo'),
        ChooseAccountType: _('labels.ChooseAccountType'),
        ChooseOffer: _('labels.ChooseOffer'),
        City: _('labels.City'),
        ClerkRegistrationComplete: _('labels.ClerkRegistrationComplete'),
        ClickAndLearnMoreAboutDataProtection: _('labels.ClickAndLearnMoreAboutDataProtection'),
        Close: _('labels.Close'),
        Commune: _('labels.Commune'),
        CompanyApartmentNumber: _('labels.CompanyApartmentNumber'),
        CompanyBuildingNumber: _('labels.CompanyBuildingNumber'),
        CompanyCity: _('labels.CompanyCity'),
        CompanyName: _('labels.CompanyName'),
        CompanyPostalCode: _('labels.CompanyPostalCode'),
        CompanyStreet: _('labels.CompanyStreet'),
        Confirm: _('labels.Confirm'),
        ConfirmAccount: _('labels.ConfirmAccount'),
        ConfirmAdult: _('labels.ConfirmAdult'),
        ConfirmPassword: _('labels.ConfirmPassword'),
        ConfirmRegulations: _('labels.ConfirmRegulations'),
        ConsentsRequired: _('labels.ConsentsRequired'),
        Counter: _('labels.Counter'),
        Country: _('labels.Country'),
        CustomerAlreadyRegistered: _('labels.CustomerAlreadyRegistered'),
        CustomerAlreadyRegistred: _('labels.CustomerAlreadyRegistred'),
        CustomerNotYetRegistered: _('labels.CustomerNotYetRegistered'),
        CustomerRegistrationTimeout: _('labels.CustomerRegistrationTimeout'),
        DateOfBirth: _('labels.DateOfBirth'),
        DealerCode: _('labels.DealerCode'),
        DeviceNumber: _('labels.DeviceNumber'),
        DoYouHaveAccount: _('labels.DoYouHaveAccount'),
        EditProfile: _('labels.EditProfile'),
        Email: _('labels.Email'),
        EmailAlreadyExists: _('labels.EmailAlreadyExists'),
        EmailShort: _('labels.EmailShort'),
        EnterPhoneNumberToVerifyAvailabilityOfPromotionalOffer: _('labels.EnterPhoneNumberToVerifyAvailabilityOfPromotionalOffer'),
        FirstName: _('labels.FirstName'),
        ForgotPassword: _('labels.ForgotPassword'),
        Form: _('labels.Form'),
        GatheredPoints: _('labels.GatheredPoints'),
        GetData: _('labels.GetData'),
        GetPointOfSaleData: _('labels.GetPointOfSaleData'),
        Here: _('labels.Here'),
        HideProgramRegulations: _('labels.HideProgramRegulations'),
        HomeNumber: _('labels.HomeNumber'),
        IConfirmTheSaleOfTheOffer: _('labels.IConfirmTheSaleOfTheOffer'),
        InformationClause: _('labels.InformationClause'),
        InformationOnPersonalDataProtection: _('labels.InformationOnPersonalDataProtection'),
        Install: _('labels.Install'),
        LastName: _('labels.LastName'),
        LegalPerson: _('labels.LegalPerson'),
        LogIn: _('labels.LogIn'),
        Logging: _('labels.Logging'),
        LoginPageGreetingsContinuation: _('labels.LoginPageGreetingsContinuation'),
        LoginPageLoginOrRegister: _('labels.LoginPageLoginOrRegister'),
        Logout: _('labels.Logout'),
        MakeSureYouHaveProvideTheCustomerWith: _('labels.MakeSureYouHaveProvideTheCustomerWith'),
        Menu: _('labels.Menu'),
        NewPassword: _('labels.NewPassword'),
        Next: _('labels.Next'),
        Nip: _('labels.Nip'),
        No: _('labels.No'),
        NoActiveProgram: _('labels.NoActiveProgram'),
        NoOffer: _('labels.NoOffer'),
        OfferPortfolio: _('labels.OfferPortfolio'),
        OfferRegulation: _('labels.OfferRegulation'),
        OfferSale: _('labels.OfferSale'),
        OfferSaleCancelation: _('labels.OfferSaleCancelation'),
        OffersRegulations: _('labels.OffersRegulations'),
        OldPasswrod: _('labels.OldPasswrod'),
        Password: _('labels.Password'),
        Pesel: _('labels.Pesel'),
        PhoneNumber: _('labels.PhoneNumber'),
        PhysicalPerson: _('labels.PhysicalPerson'),
        PlatformRegulations: _('labels.PlatformRegulations'),
        Ploom: _('labels.Ploom'),
        PloomRegistrationHeader: _('labels.PloomRegistrationHeader'),
        PointOfSaleCode: _('labels.PointOfSaleCode'),
        PostOffice: _('labels.PostOffice'),
        PostalCode: _('labels.PostalCode'),
        PrivacyPolicy: _('labels.PrivacyPolicy'),
        ProcessCanceledBySeller: _('labels.ProcessCanceledBySeller'),
        Profile: _('labels.Profile'),
        ProgramArchive: _('labels.ProgramArchive'),
        ProgramRegulations: _('labels.ProgramRegulations'),
        ProgramsForYou: _('labels.ProgramsForYou'),
        ProvideSalePointCode: _('labels.ProvideSalePointCode'),
        Pts: _('labels.Pts'),
        QRSalePoint: _('labels.QRSalePoint'),
        QuickApplicationGuide: _('labels.QuickApplicationGuide'),
        Refresh: _('labels.Refresh'),
        Register: _('labels.Register'),
        RegistrationFailure: _('labels.RegistrationFailure'),
        RegistrationSuccess: _('labels.RegistrationSuccess'),
        RegistrationSuccessZabka: _('labels.RegistrationSuccessZabka'),
        Regulation: _('labels.Regulation'),
        Regulations: _('labels.Regulations'),
        RememberMe: _('labels.RememberMe'),
        RepeatEmail: _('labels.RepeatEmail'),
        RestorePassword: _('labels.RestorePassword'),
        RestorePasswordInfo: _('labels.RestorePasswordInfo'),
        Reward: _('labels.Reward'),
        RtNumber: _('labels.RtNumber'),
        SaleFailedMessage: _('labels.SaleFailedMessage'),
        SaleSuccessfulMessage: _('labels.SaleSuccessfulMessage'),
        SaleSuccessfullAdditionalWarning: _('labels.SaleSuccessfullAdditionalWarning'),
        Save: _('labels.Save'),
        SavedSuccessFully: _('labels.SavedSuccessFully'),
        ScanOrEnterTheQrCodeOfTheDevice: _('labels.ScanOrEnterTheQrCodeOfTheDevice'),
        ScanOrEnterTheQrCodeOfThePointWhereYouAreCurrentlyWorking: _('labels.ScanOrEnterTheQrCodeOfThePointWhereYouAreCurrentlyWorking'),
        SearchForCode: _('labels.SearchForCode'),
        SelectOfferInfo: _('labels.SelectOfferInfo'),
        SelectProgram: _('labels.SelectProgram'),
        SellOfferProcessInProgress: _('labels.SellOfferProcessInProgress'),
        SendLink: _('labels.SendLink'),
        ShowLess: _('labels.ShowLess'),
        ShowMore: _('labels.ShowMore'),
        ShowProgramRegulations: _('labels.ShowProgramRegulations'),
        Street: _('labels.Street'),
        TaxOffice: _('labels.TaxOffice'),
        TheOrganizerOfThePromotionIsJtiPoland: _('labels.TheOrganizerOfThePromotionIsJtiPoland'),
        Verify: _('labels.Verify'),
        Voivodeship: _('labels.Voivodeship'),
        WaitForClientRegisterMessage: _('labels.WaitForClientRegisterMessage'),
        Welcome: _('labels.Welcome'),
        WrongRegistrationUrl: _('labels.WrongRegistrationUrl'),
        Yes: _('labels.Yes'),
        YouCanFindRegulationLink: _('labels.YouCanFindRegulationLink'),
        YourEmail: _('labels.YourEmail'),
        YourPoints: _('labels.YourPoints'),
        ZabkaRegisterDeviceViewMessage: _('labels.ZabkaRegisterDeviceViewMessage'),
};
