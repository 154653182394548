import "./loading-counter.scss"

export default function LoadingCounter() {
  return (
    <div className="loading-counter">
      <div className="loading-counter__overlay"></div>
      <div className="loading-counter_googey">
        <div className="gooey">
          <span className="dot"></span>
          <div className="dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div className="loading-counter dark">
        <div className="loading-counter_googey">
          <div className="gooey">
            <span className="dot"></span>
            <div className="dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}