import "./ploom-register-customer.scss"
import { ErrorMessage } from "@hookform/error-message"
import { getConsents, verifyCustomer, registerCustomer, getRegulationLink } from "../../common/services/customerService";
import { pageStates } from "../../common/consts/pageStates";
import { useForm } from "react-hook-form"
import { useParams } from 'react-router-dom'
import Button from "../ui/Button/Button";
import Checkbox from "../ui/Checkbox/Checkbox";
import clsx from "clsx";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InfoPage from "../info/info";
import langs from "../../common/services/languageService";
import LoadingCounter from '../ui/loading-counter/loading-counter.jsx'
import React, { useEffect, useState, useRef } from "react"
import Row from 'react-bootstrap/Row';
import Connector from "../../common/services/signalrService"
import { formatPhoneNumber } from "../../common/helpers/form-helper.js"

const { labels, validationMessages } = langs;
const guid_pattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

export default function PloomRegisterCustomer() {
  document.title = "Ploom Rejestracja";

  const params = useParams();
  const { register, formState: { errors, isValid, isSubmitted }, handleSubmit, setValue, control, reset } = useForm({
    criteriaMode: "all", mode: "onChange"
  });

  const { completeRegistration, terminateRegistration, listenOnTransaction, events } = Connector();

  useEffect(() => {
    events(() => {
      setVerificationState(pageStates.processCanceled);
    });
  });

  const [consents, setConsents] = useState([]);
  const [isDataLoading, setIsDataLoadingState] = useState(false);
  const [verificationState, setVerificationState] = useState(pageStates.verified);
  const [offerId, setOfferId] = useState(null);
  const bodyEl = useRef(null);
  let loadingCounter = 0;

  const Init = () => {
    var idIsValid = guid_pattern.test(params.transactionId);

    if (idIsValid) {
      setIsDataLoadingState(++loadingCounter > 0);
      getConsents()
        .then((res) => {
          setIsDataLoadingState(--loadingCounter > 0);
          setConsents(res.data.consents);
        }).catch(() => {
          setIsDataLoadingState(--loadingCounter > 0);
        });

      setIsDataLoadingState(++loadingCounter > 0);
      verifyCustomer(params.transactionId)
        .then((res) => {
          setIsDataLoadingState(--loadingCounter > 0);
          setValue('phoneNumber', formatPhoneNumber(res.data.phoneNumber.substring(2)));
          setOfferId(res.data.offerId);

          if (!res.data.transactionValid) {
            setVerificationState(pageStates.wrongRegistrationUrl);
          }
          else if (res.data.alreadyRegistred) {
            setVerificationState(pageStates.alreadyRegistred);
          }
          else if (res.data.linkExpired) {
            setVerificationState(pageStates.registrationTimeout);
          }
          else {
            listenOnTransaction(params.transactionId);
          }
        })
        .catch(() => {
          setIsDataLoadingState(--loadingCounter > 0);
            setVerificationState(pageStates.unknownError);
        });
    }
    else {
      setVerificationState(pageStates.wrongRegistrationUrl);
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      bodyEl?.current?.scroll({
        top: bodyEl?.current?.scrollHeight,
        behavior: 'smooth',
      });
    }, 50)
  };

  const onSubmit = (data) => {
    var payload = {
      ...data,
      phoneNumber: 48 + data.phoneNumber.replaceAll(" ", ""),
      consents: consents.map(c => c.id),
      transactionId: params.transactionId
    };

    setIsDataLoadingState(++loadingCounter > 0);
    registerCustomer(payload)
      .then((resp) => {
        if (resp.data) {
          if (!window.config.customerRegistrationConfirmationTurnedOff) {
            completeRegistration(params.transactionId);
          }
          setVerificationState(pageStates.registrationSuccess)
        }
        else {
          terminateRegistration(params.transactionId)
          setVerificationState(pageStates.registrationTimeout)
        }
        setIsDataLoadingState(--loadingCounter > 0);
      })
      .catch(() => {
        terminateRegistration(params.transactionId)
        setIsDataLoadingState(--loadingCounter > 0);
      })
  }

  const isAdult = (birthdate) => {
    birthdate = new Date(birthdate);
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    return birthdate <= eighteenYearsAgo
  }

  useEffect(() => {
    Init();
  }, [])

  if (verificationState != pageStates.verified)
    return <InfoPage pageState={verificationState} />
  else
    return (
      <Container>
        {isDataLoading &&
          <LoadingCounter />
        }
        <form className="ploom" onSubmit={handleSubmit(onSubmit)}>
          <div className="ploom__header">
            <div className="smallContainer">
              <p>{labels.PloomRegistrationHeader()}</p>
              <p className="bold" onClick={() => scrollToBottom()}>{labels.Ploom()}</p>
            </div>
          </div>
          <div className="ploom__body" ref={bodyEl}>
            <div className="smallContainer">
              <Row>
                <Col>
                  <label className="consent-required">*{labels.ConsentsRequired()}</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label htmlFor="name">{labels.FirstName()}<span>*</span></Form.Label>
                  <Form.Control
                    {...register("name", {
                      required: validationMessages.Required(),
                      minLength: {
                        value: 2,
                        message: validationMessages.MinimumLength().replace("{0}", "2"),
                      },
                      maxLength: {
                        value: 100,
                        message: validationMessages.MaximumLength().replace("{0}", "100"),
                      },
                    })}
                    id="name"
                  />
                  <div className="danger_container">
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <span key={type} className='danger'>{message}</span>
                        ))
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label htmlFor="lastName">{labels.LastName()}<span>*</span></Form.Label>
                  <Form.Control
                    {...register("lastName", {
                      required: validationMessages.Required(),
                      minLength: {
                        value: 2,
                        message: validationMessages.MinimumLength().replace("{0}", "2"),
                      },
                      maxLength: {
                        value: 100,
                        message: validationMessages.MaximumLength().replace("{0}", "100"),
                      },
                    })}
                    id="lastName"
                  />
                  <div className="danger_container">
                    <ErrorMessage
                      errors={errors}
                      name="lastName"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <span key={type} className='danger'>{message}</span>
                        ))
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label htmlFor="birthdate">{labels.DateOfBirth()}<span>*</span></Form.Label>
                  <Form.Control
                    type="date"
                    id="birthdate"
                    {...register("birthdate", {
                      required: validationMessages.Required(),
                      validate: isAdult
                    })}
                  />
                  <div className="danger_container">
                    <ErrorMessage
                      errors={errors}
                      name="birthdate"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <span key={type} className='danger'>{message}</span>
                        ))
                      }
                    />
                    {
                      errors.birthdate && errors.birthdate.type === "validate" && (
                        <span className='danger'>{validationMessages.ForAdultOnly()}</span>
                      )
                    }
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label htmlFor="email">{labels.Email()}<span>*</span></Form.Label>
                  <Form.Control
                    type="email"
                    {...register("email", {
                      required: validationMessages.Required(),
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: validationMessages.Email()
                      },
                      maxLength: {
                        value: 200,
                        message: validationMessages.MaximumLength().replace("{0}", "200"),
                      },
                    })}
                    id="email"
                  />
                  <div className="danger_container">
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <span key={type} className='danger'>{message}</span>
                        ))
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label htmlFor="phoneNumber">{labels.PhoneNumber()}<span>*</span></Form.Label>
                  <div className='phone-number'>
                    <span className='phone-number--prefix'>
                      +48
                    </span>
                    <Form.Control
                      disabled={true}
                      id='phoneNumber'
                      placeholder="000 000 000"
                      inputMode="numeric"
                      {...register("phoneNumber")}
                    />
                  </div>
                  <div className="danger_container">
                    <ErrorMessage
                      errors={errors}
                      name="phoneNumber"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <span key={type} className='danger'>{message}</span>
                        ))
                      }
                    />
                  </div>
                </Col>
              </Row>
              {/* Temporary hidden */}
              {/* <a className="program-regulations" href={getRegulationLink(RegulationsTypes.programForCustomer, offerId)}>{labels.ProgramRegulations()}</a> */}
              <Row>
                <Col>
                  <div className='consents'>
                    {consents.map((consent, index) => (
                      <div key={consent.id}>
                        <div className='consents--item'>
                          <Form.Label htmlFor={`consents[${index}]`}>
                            <div dangerouslySetInnerHTML={{ __html: consent.text + '<span>*</span>' }} />
                            <div className={clsx("danger", errors.consents && errors.consents[index] ? "show" : "hidden")}>{validationMessages.Required()}</div>
                          </Form.Label>
                          <Checkbox
                            id={`consents[${index}]`}
                            name={`consents[${index}]`}
                            control={control}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
              <div className={clsx("danger", errors.consents ? "show" : "hidden")}>{validationMessages.ConsentsRequired()}</div>
            </div>
          </div>
          <div className="ploom__footer smallContainer">
            <Button type="submit" disabled={isSubmitted && !isValid}>{labels.Save()}</Button>
          </div>
        </form>
      </Container >
    );
}