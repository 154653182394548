import "./registration-success.scss"
import React from "react"
import langs from "./../../common/services/languageService";
import { useLocation } from 'react-router-dom'

const { labels } = langs;

export default function RegistrationSuccess() {
  const location = useLocation();
  const message = location.state.message;

  return (
    <div className="ploom-success">
      <div className="ploom-success__header">
        <p>{labels.Form()}<span className="bold"> {labels.Ploom()}</span></p>
      </div>
      <div className="ploom-success__body">
        <div className="ploom-success__body--info" dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    </div>
  )
}