import "./ploom-register-form.scss"
import { ErrorMessage } from "@hookform/error-message"
import { externalAppType } from "../../common/consts/externalAppType"
import { getConsents, registerDevice, registerCustomer, validateCustomer, verifySalePoint, getRegulationUrl, checkIsOfferAvaliable, getOfferIdByExternalId, confirmPurchaseWithoutDevice } from "../../common/services/externalService";
import { salesChannels } from "../../common/consts/SalesChannels";
import { useForm } from "react-hook-form"
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from "../ui/Button/Button";
import Checkbox from "../ui/Checkbox/Checkbox";
import clsx from "clsx";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import langs from "./../../common/services/languageService";
import LoadingCounter from '../ui/loading-counter/loading-counter.jsx'
import React, { useEffect, useState, useRef } from "react"
import ReCAPTCHA from "react-google-recaptcha";
import Row from 'react-bootstrap/Row';
import { formatPhoneNumber } from "../../common/helpers/form-helper.js"

const { labels, validationMessages } = langs;

export default function PloomRegisterForm({ isLightApp }) {
  const params = useParams();
  const location = useLocation();
  const appType = location.pathname.startsWith("/light") ? externalAppType.light : externalAppType.zabka;
  const { register, formState: { errors, isValid, isSubmitted }, handleSubmit, setValue, control, reset } = useForm({
    criteriaMode: "all", mode: "onChange"
  });
  const [consents, setConsents] = useState([]);
  const [dependentOffers, setdependentOffers] = useState([]);
  const [phoneNumberChecked, setPhoneNumberCheckedState] = useState(false);
  const [registerCustomerView, setRegisterCustomerViewState] = useState(false);
  const [registerDeviceView, setRegisterDeviceViewState] = useState(false);
  const [dependentOffersView, setDependentOffersViewState] = useState(false);
  const [noOfferError, setNoOfferErrorState] = useState(false);
  const [pointOfSaleWrongError, setPointOfSaleWrongErrorState] = useState(false);
  const [wrongDeviceError, setWrongDeviceErrorState] = useState(false);
  const [isDeviceForUserRegistered, setIsDeviceForUserRegisteredState] = useState(false);
  const [isDataLoading, setIsDataLoadingState] = useState(false);
  const [transactionId, setTransactionIdState] = useState(0);
  const [salePointError, setSalePointErrorState] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(true);
  const [captchaKey, setCaptchaKey] = useState(false);
  const [offerId, setOfferId] = useState(null);

  const navigate = useNavigate();
  const bodyEl = useRef(null);
  const recaptchaRef = useRef(null);

  let loadingCounter = 0;

  const success = () => {
    var message = "";

    if (isLightApp) {
      message = dependentOffersView ? labels.SavedSuccessFully() : labels.RegistrationSuccess()
    }
    else {
      message = labels.RegistrationSuccessZabka()
    }

    navigate('/success', { state: { message: message } });
  }

  const setTitle = () => {
    var title = isLightApp ? "B2B2C Light" : "Żabka";
    document.title = title;
  }

  setTitle();

  const onReCaptchaChange = (key) => {
    setCaptchaKey(key);
    setCaptchaVerified(true);
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      bodyEl?.current?.scroll({
        top: bodyEl?.current?.scrollHeight,
        behavior: 'smooth',
      });
    }, 50)
  };

  const onSubmit = (data) => {
    const phoneNumber = data.phoneNumber;
    var payload = {
      ...data,
      offerId: offerId,
      phoneNumber: 48 + phoneNumber.replaceAll(" ", ""),
      consents: consents.map(c => c.id),
      transactionId: transactionId,
    };
    // Oferty zależne
    if (dependentOffersView) {
      setIsDataLoadingState(++loadingCounter > 0)
      confirmPurchaseWithoutDevice({ ...data, transactionId: transactionId }).then((resp) => {
        if (!resp.data.isRegistrationSuccessfull) {
          if (resp.data.isOfferAvaliable === false) {
            setNoOfferErrorState(true)
          }
          if (resp.data.isPointOfSaleValid === false) {
            setPointOfSaleWrongErrorState(true)
          }
        }
        else {
          success();
        }
        setIsDataLoadingState(--loadingCounter > 0)
      }).catch(() => {
        setIsDataLoadingState(--loadingCounter > 0)
      })
    }
    // Weryfikacja numeru kom
    if (!phoneNumberChecked || !(registerCustomerView || registerDeviceView || dependentOffersView)) {
      setIsDataLoadingState(++loadingCounter > 0)
      resetCaptcha();
      validateCustomer({ phoneNumber: payload.phoneNumber, channel: payload.channel, captchaKey: captchaKey, offerId: offerId }, isLightApp)
        .then((resp) => {
          const data = resp.data;
          reset();
          setValue('phoneNumber', phoneNumber);
          setPhoneNumberCheckedState(true);
          setTransactionIdState(resp.headers['transactionid']);

          // Pokjazujemy oferte z ofertami zależnymi
          if (data.isDeviceForUserRegistered && data.availableDependentOffers?.length > 0) {

            if (data.availableDependentOffers.length == 1) {
              setValue('offerId', data.availableDependentOffers[0].id + "");
            }

            setDependentOffersViewState(true);
            setdependentOffers(resp.data.availableDependentOffers);
            setCaptchaVerified(true);
          }
          // Brak oferty - oferta kupiona i brak ofert zależnych
          else if (data.isDeviceForUserRegistered && data.isOfferAvaliable && data.isUserRegistered) {
            setNoOfferErrorState(true);
          }
          // Pokazujemy rejestracje użytkownika i urządzenia
          else {
            setIsDeviceForUserRegisteredState(data.isRegistrationSuccessfull);
            setRegisterCustomerView(data);
            setRegisterDeviceView(data);
            setNoOfferErrorState(!data.isOfferAvaliable);
          }

          setIsDataLoadingState(--loadingCounter > 0);
        })
        .catch(() => {
          setIsDataLoadingState(--loadingCounter > 0)
        })
    }
    // Strona rejestracji urządzenia
    if (registerDeviceView) {
      handleRegistration(registerDevice, payload)
    }
    // Strona rejestracji użytkownika
    if (registerCustomerView) {
      handleRegistration(registerCustomer, payload)
    }
  }

  const resetCaptcha = () => {
    recaptchaRef?.current?.reset();
    setCaptchaVerified(false);
  }

  const setRegisterCustomerView = (data) => {
    var isCustomerView = !data.isUserRegistered && !data.isRegistrationSuccessfull && data.isOfferAvaliable;

    if (isCustomerView)
      setCaptchaVerified(true);

    setRegisterCustomerViewState(isCustomerView);
  }

  const setRegisterDeviceView = (data) => {
    var isRegisterDeviceView = data.isOfferAvaliable && data.isUserRegistered && !data.isDeviceForUserRegistered;

    if (isRegisterDeviceView)
      setCaptchaVerified(true);

    setRegisterDeviceViewState(isRegisterDeviceView);
  }

  const handleRegistration = (registerFunc, payload) => {
    setIsDataLoadingState(++loadingCounter > 0)
    registerFunc(payload, isLightApp)
      .then((resp) => {
        if (resp.data.isRegistrationSuccessfull && resp.data.isDeviceCodeAvaliable)
          success();
        else {
          setNoOfferErrorState(false);
          setSalePointErrorState(false);
          setWrongDeviceErrorState(false);

          if (!resp.data.isDeviceCodeAvaliable) {
            setWrongDeviceErrorState(true);
          }
          if (isLightApp && !resp.data.isPointOfSaleValid) {
            setSalePointErrorState(true);
          }
          if (!resp.data.isOfferAvaliable) {
            setNoOfferErrorState(true);
          }
          scrollToBottom();
          setIsDataLoadingState(--loadingCounter > 0)
        }
      })
      .catch(() => {
        setIsDataLoadingState(--loadingCounter > 0)
      })
  }

  const isAdult = (birthdate) => {
    birthdate = new Date(birthdate);
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    return birthdate <= eighteenYearsAgo
  }

  const handlePhoneNumberChange = (event) => {
    const inputNumber = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
    let formattedNumber = formatPhoneNumber(inputNumber);

    setValue('phoneNumber', formattedNumber, { shouldValidate: isSubmitted });
  };

  useEffect(() => {
    if (isLightApp) {
      const urlOfferId = location.pathname.split('/')[2];

      setOfferId(urlOfferId);

      setIsDataLoadingState(++loadingCounter > 0);
      checkIsOfferAvaliable(urlOfferId, salesChannels.B2B)
        .then((resp) => {
          setIsDataLoadingState(--loadingCounter > 0)
          if (!resp.data)
            navigate('/no-offer');
        })
        .catch(() => {
          setIsDataLoadingState(--loadingCounter > 0)
          navigate('/error');
        })
    }
    else {
      getOfferIdByExternalId(params.externalId)
        .then((resp) => {
          if (!resp.data) {
            navigate('/no-offer');
          }
          else {
            var title = isLightApp ? "B2B2C Light" : "Żabka";
            setOfferId(resp.data);
            window.history.replaceState(null, title, "/")
          }
        })
        .catch(() => {
          navigate('/');
        })
    }

    setIsDataLoadingState(++loadingCounter > 0);
    getConsents(isLightApp)
      .then((res) => {
        setIsDataLoadingState(--loadingCounter > 0)
        setConsents(res.data.consents)
      })
      .catch(() => {
        setIsDataLoadingState(--loadingCounter > 0)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      {isDataLoading &&
        <LoadingCounter />
      }
      <form className="ploom" onSubmit={handleSubmit(onSubmit)}>
        <div className="ploom__header">
          {
            isLightApp && <div className="smallContainer">
              <p>{labels.Form()}<span className="bold"> {labels.Ploom()}</span></p>
            </div>
          }
          {
            !isLightApp && <div className="smallContainer">
              <p>{labels.PloomRegistrationHeader()}</p>
              <p className="bold">{labels.Ploom()}</p>
            </div>
          }
        </div>
        <div className="ploom__body" ref={bodyEl}>
          <div className="smallContainer">
            {
              registerDeviceView &&
              <p>{labels.ZabkaRegisterDeviceViewMessage()}</p>
            }
            {
              registerCustomerView &&
              <div>
                <Row>
                  <Col>
                    <label className="consent-required">*{labels.ConsentsRequired()}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label htmlFor="name">{labels.FirstName()}<span>*</span></Form.Label>
                    <Form.Control
                      {...register("name", {
                        required: validationMessages.Required(),
                        minLength: {
                          value: 2,
                          message: validationMessages.MinimumLength().replace("{0}", "2"),
                        },
                        maxLength: {
                          value: 100,
                          message: validationMessages.MaximumLength().replace("{0}", "100"),
                        },
                      })}
                      id="name"
                    />
                    <div className="danger_container">
                      <ErrorMessage
                        errors={errors}
                        name="name"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <span key={type} className='danger'>{message}</span>
                          ))
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label htmlFor="lastName">{labels.LastName()}<span>*</span></Form.Label>
                    <Form.Control
                      {...register("lastName", {
                        required: validationMessages.Required(),
                        minLength: {
                          value: 2,
                          message: validationMessages.MinimumLength().replace("{0}", "2"),
                        },
                        maxLength: {
                          value: 100,
                          message: validationMessages.MaximumLength().replace("{0}", "100"),
                        },
                      })}
                      id="lastName"
                    />
                    <div className="danger_container">
                      <ErrorMessage
                        errors={errors}
                        name="lastName"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <span key={type} className='danger'>{message}</span>
                          ))
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label htmlFor="birthdate">{labels.DateOfBirth()}<span>*</span></Form.Label>
                    <Form.Control
                      type="date"
                      id="birthdate"
                      {...register("birthdate", {
                        required: validationMessages.Required(),
                        validate: isAdult
                      })}
                    />
                    <div className="danger_container">
                      <ErrorMessage
                        errors={errors}
                        name="birthdate"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <span key={type} className='danger'>{message}</span>
                          ))
                        }
                      />
                      {
                        errors.birthdate && errors.birthdate.type === "validate" && (
                          <span className='danger'>{validationMessages.ForAdultOnly()}</span>
                        )
                      }
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label htmlFor="email">{labels.Email()}<span>*</span></Form.Label>
                    <Form.Control
                      type="email"
                      {...register("email", {
                        required: validationMessages.Required(),
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: validationMessages.Email()
                        },
                        maxLength: {
                          value: 200,
                          message: validationMessages.MaximumLength().replace("{0}", "200"),
                        },
                      })}
                      id="email"
                    />
                    <div className="danger_container">
                      <ErrorMessage
                        errors={errors}
                        name="email"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <span key={type} className='danger'>{message}</span>
                          ))
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            }
            <Row>
              <Col>
                <Form.Label htmlFor="phoneNumber">{registerCustomerView || registerDeviceView || dependentOffersView ? labels.PhoneNumber() : labels.EnterPhoneNumberToVerifyAvailabilityOfPromotionalOffer()}<span>*</span></Form.Label>
                <div className='phone-number'>
                  <span className='phone-number--prefix'>
                    +48
                  </span>
                  <Form.Control
                    disabled={registerCustomerView || registerDeviceView || dependentOffersView}
                    id='phoneNumber'
                    placeholder="000 000 000"
                    inputMode="numeric"
                    {...register("phoneNumber", {
                      required: validationMessages.Required(),
                      pattern: {
                        value: /^\d{3} \d{3} \d{3}$/,
                        message: validationMessages.PhoneNumber(),
                      },
                    })}
                    onChange={handlePhoneNumberChange}
                  />
                </div>
                <div className="danger_container">
                  <ErrorMessage
                    errors={errors}
                    name="phoneNumber"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <span key={type} className='danger'>{message}</span>
                      ))
                    }
                  />
                </div>
              </Col>
            </Row>
            {(dependentOffersView || (registerDeviceView && isLightApp)) &&
              // Kod sklepu
              <Row>
                <Col>
                  <Form.Label>{labels.PointOfSaleCode()}<span>*</span></Form.Label>
                  <Form.Control
                    id='dealerCode'
                    {...register("dealerCode", {
                      required: validationMessages.Required()
                    })}
                  />
                  <div className="danger_container">
                    <ErrorMessage
                      errors={errors}
                      name="dealerCode"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <span key={type} className='danger'>{message}</span>
                        ))
                      }
                    />
                    {pointOfSaleWrongError &&
                      <span class="danger">{validationMessages.WrongSalePoint()}</span>
                    }
                  </div>
                </Col>
              </Row>
            }
            {dependentOffersView &&
              // Oferty zależne
              <Row>
                <Col>
                  <Form.Label>{labels.ChooseOffer()}<span>*</span></Form.Label>
                  <div className="danger_container">
                    <ErrorMessage
                      errors={errors}
                      name="offerId"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <span key={type} className='danger'>{message}</span>
                        ))
                      }
                    />
                    {noOfferError &&
                      <span class="danger">{validationMessages.OfferNotAvailableMessage()}</span>
                    }
                  </div>
                  {dependentOffers.map((offer, index) =>
                  (
                    <div key={offer.id}>
                      <Form.Check
                        type="radio"
                        label={offer.name}
                        value={offer.id}
                        id={offer.name}
                        {...register("offerId", {
                          required: validationMessages.Required()
                        })}
                      />
                    </div>
                  )
                  )}
                </Col>
              </Row>
            }
            {
              isLightApp && registerCustomerView &&
              <Row>
                <Col>
                  <Form.Label htmlFor="dealerCode">{labels.DealerCode()}<span>*</span></Form.Label>
                  <Form.Control
                    id='dealerCode'
                    {...register("dealerCode", {
                      required: validationMessages.Required(),
                      maxLength: {
                        value: 50,
                        message: validationMessages.MaximumLength().replace("{0}", "50"),
                      },
                    })}
                  />
                  <div className="danger_container">
                    <ErrorMessage
                      errors={errors}
                      name="dealerCode"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <span key={type} className='danger'>{message}</span>
                        ))
                      }
                    />
                  </div>
                </Col>
              </Row>
            }
            {
              (registerCustomerView || registerDeviceView) &&
              <div>
                < Row >
                  <Col>
                    <Form.Label htmlFor="deviceNumber">{labels.DeviceNumber()}<span>*</span></Form.Label>
                    <Form.Control
                      id='deviceNumber'
                      {...register("deviceNumber", {
                        required: validationMessages.Required(),
                        pattern: {
                          value: /^.{12}$/,
                          message: validationMessages.FixedLength().replace("{0}", "12"),
                        },
                      })}
                    />
                    <div className="danger_container">
                      <ErrorMessage
                        errors={errors}
                        name="deviceNumber"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <span key={type} className='danger'>{message}</span>
                          ))
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <p className="ploom__body--search-codes">{labels.SearchForCode().toUpperCase()}</p>
                <div className="ploom__body--box">
                  <img className="box" src={process.env.PUBLIC_URL + '/img/box_ploom.png'} alt="" />
                  <img className="arrow" src={process.env.PUBLIC_URL + '/img/arrow-ltr.png'} alt="" />
                </div>
                {
                  registerCustomerView &&
                  < Row >
                    <Col>
                      <div className='consents'>
                        {consents.map((consent, index) => (
                          <div key={consent.id}>
                            <div className='consents--item'>
                              <Form.Label htmlFor={`consents[${index}]`}>
                                <div dangerouslySetInnerHTML={{ __html: consent.text + '<span>*</span>' }} />
                                <div className={clsx("danger", errors.consents && errors.consents[index] ? "show" : "hidden")}>{validationMessages.Required()}</div>
                              </Form.Label>
                              <Checkbox
                                id={`consents[${index}]`}
                                name={`consents[${index}]`}
                                control={control}
                                rules={{ required: true }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                }
                <div className={clsx("danger", errors.consents ? "show" : "hidden")}>{validationMessages.ConsentsRequired()}</div>
              </div>
            }
            {
              ((noOfferError || wrongDeviceError) && !dependentOffersView) &&
              <Row>
                <Col>
                  <p className='danger'>{noOfferError ? validationMessages.NoPromotionOffer() : validationMessages.WrongDeviceNumber()}</p>
                </Col>
              </Row>
            }
            {
              salePointError &&
              <Row>
                <Col>
                  <p className='danger'>{validationMessages.SalePointDoesntExist()}</p>
                </Col>
              </Row>
            }
            {
              !registerCustomerView && !registerDeviceView && !isLightApp &&
              <Row>
                <Col>
                  <a target="_blank" href={getRegulationUrl(offerId)} rel="noreferrer">{labels.ProgramRegulations()}</a>
                </Col>
              </Row>
            }
            {(!phoneNumberChecked || !(registerCustomerView || registerDeviceView || dependentOffersView)) && <div className="buzz_dynamic_image" />}
          </div>
        </div>
        <div className="ploom__footer smallContainer">
          {!registerCustomerView && !registerDeviceView && !dependentOffersView &&
            <div className="re-captcha">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={window.config.captchaKey}
                onChange={key => onReCaptchaChange(key)}
              />
            </div>
          }
          <Button type="submit" disabled={isSubmitted && !isValid || !captchaVerified}>
            {(!phoneNumberChecked || isDeviceForUserRegistered) && !dependentOffersView ? labels.Verify() : labels.Save()}
          </Button>
        </div>
      </form >
    </Container >
  );
}