import * as signalR from "@microsoft/signalr";

const hubUrl = window.config.hubUrl;

class Connector {
  connection;
  events;
  static instance;

  constructor() {
    this.connection = new signalR
      .HubConnectionBuilder()
      .withUrl(hubUrl)
      .withAutomaticReconnect()
      .build();

    this.connection.start().catch(err => document.write(err));

    this.events = (onCancelRegistration) => {
      this.connection.on("CancelRegistration", () => {
        console.log("Registration canceled")
        onCancelRegistration();
      });
    };
  }

  listenOnTransaction = (transactionId) => {
    this.connection.send("ListenOnRegistration", transactionId).then(x => console.log(`Transaction ${transactionId} started...`));
  }

  completeRegistration = (transactionId) => {
    this.connection.send("CompleteRegistration", transactionId).then(x => console.log(`Registration ${transactionId} completed...`));
  }

  terminateRegistration = (transactionId) => {
    this.connection.send("TerminateRegistration", transactionId).then(x => console.log(`Registration ${transactionId} terminated...`));
  }

  static getInstance() {
    if (!Connector.instance)
      Connector.instance = new Connector();

    return Connector.instance;
  }
}

export default Connector.getInstance;