export const formatPhoneNumber = (phoneNumber) => {
  var formattedNumber = "";

  for (let i = 0; i < phoneNumber.length; i++) {
    if (i === 3 || i === 6) {
      formattedNumber += ' ';
    }

    if (i === 9)
      continue;

    formattedNumber += phoneNumber[i];
  }

  return formattedNumber;
}


