import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './Button.module.scss';

const propTypes = {
    bootstrapButtonType: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string,]),
};

const defaultProps = {
    type: "button",
    bootstrapButtonType: "primary"
};

function Button({ bootstrapButtonType, className, disabled, label, children, onClick }) {
    const onClickHandle = useCallback((e) => {
        if (onClick) onClick(e);
    }, [onClick]);

    return (
        <button
            type="submit"
            disabled={disabled}
            onClick={onClickHandle}
            className={clsx(styles.button, `btn--${bootstrapButtonType}`, className, disabled && styles.disabled)}>
            {children}
        </button>

    );
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default memo(Button);
