/* eslint-disable import/no-anonymous-default-export*/
// eslint-disable import/no-anonymous-default-export
// File was autogenerated by tools/generateLabels.js script
import labels from  './labels';
import templates from  './templates';
import validationMessages from  './validationMessages';

export default {
    labels,
    templates,
    validationMessages,
};
