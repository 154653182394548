import resources from '../consts/resources';

class ResourceService {
    static get(path) {
        let dictAndLang = path.split('.');
        let currLanguage = "pl";
        return resources[dictAndLang[0]][currLanguage][dictAndLang[1]];
    }
}

export default ResourceService;
