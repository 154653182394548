import axios from "axios";

var zabkaUrl = window.config.zabkaApiUrl;
var lightUrl = window.config.lightApiUrl;

export const validateCustomer = (model, isLightApp) => {
  const baseUrl = isLightApp ? lightUrl : zabkaUrl;
  return axios.post(baseUrl + 'customer/validate', model)
}

export const registerDevice = (model, isLightApp) => {
  const baseUrl = isLightApp ? lightUrl : zabkaUrl;
  return axios.post(baseUrl + 'purchase/confirm', model)
}

export const registerCustomer = (model, isLightApp) => {
  const baseUrl = isLightApp ? lightUrl : zabkaUrl;
  return axios.post(baseUrl + 'customer/register', model)
}

export const getConsents = (isLightApp) => {
  const baseUrl = isLightApp ? lightUrl : zabkaUrl;
  return axios.get(baseUrl + 'customer/consents')
}

export const getRegulationUrl = (offerId) => {
  return `/files/${offerId}`;
}

export const checkIsOfferAvaliable = (offerId, channel) => {
  return axios.post(lightUrl + `offer/verify`, { offerId: offerId, channel: channel });
}

export const getOfferIdByExternalId = (externalId) => {
  return axios.get(zabkaUrl + `offer/extId/${externalId}`)
}

export const confirmPurchaseWithoutDevice = (model) => {
  return axios.post(lightUrl + `purchase/no-device/confirm`, model)
}


