import "./no-offer.scss"
import React from "react"
import langs from "../../common/services/languageService";

const { labels } = langs;

export default function NoOffer() {

  return (
    <div className="ploom-no-offer">
      <div className="ploom-no-offer__header">
        <p>{labels.PloomRegistrationHeader()}</p>
        <p className="bold">{labels.Ploom()}</p>
      </div>
      <div className="ploom-no-offer__body">
        <div className="ploom-no-offer__body--info" dangerouslySetInnerHTML={{ __html: labels.NoOffer() }} />
      </div>
    </div>
  )
}