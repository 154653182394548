/* eslint-disable import/no-anonymous-default-export*/
// eslint-disable import/no-anonymous-default-export
// File was autogenerated by tools/generateLabels.js script

export default {
    ConsentsRequired: "Wszystkie zgody są wymagane.",
    CurrentlyWeDoNotHavePromotialOfferForThisConsumer: "Nie możesz skorzystać z promocji. Wprowadź ponownie inny numer telefonu lub zakończ proces.",
    Email: "To pole musi zawierać adres email.",
    EmailNotMatch: "Podane adresy e-mail muszą być takie same.",
    FixedLength: "To pole powinno się składać z {0} znaków.",
    ForAdultOnly: "Z promocji mogą skorzystać tylko osoby pełnoletnie.",
    MaximumLength: "To pole powinno się składać z maksymalnie {0} znaków.",
    MinimumLength: "To pole powinno się składać z co najmniej {0} znaków.",
    NoPromotionOffer: "Jesteś już zarejestrowany w bazie marketingowej JTI i na ploom.pl lub osiągnięto limit ofert, dlatego nie możesz skorzystać z Programu. Czekaj na kolejne okazje!",
    OfferNotAvailableMessage: "Brak możliwości skorzystania z promocji.\r\nOferta jest niedostępna.",
    PasswordCanotBeSame: "Nowe hasło musi różnić się od starego.",
    PasswordNotMatch: "Pole hasło i powtórz hasło muszą zawierać tą samą wartość",
    PasswordRequirements: "Hasło powinno posiadać co najmniej 8 znaków w tym jedną dużą literę oraz cyfrę lub znak specjalny.",
    PhoneNumber: "Proszę wpisać poprawny numer telefonu.",
    PostalCode: "Podano błędny kod pocztowy",
    Required: "To pole jest wymagane.",
    SalePointAndRtNotMatch: "Niepoprawna kombinacja numeru RT i kodu QR punktu.",
    SalePointDoesntExist: "Podano błędny kod punktu sprzedaży.",
    UnknownErrorMessage: "Wystąpił nieznany błąd. Spróbuj ponownie później.",
    WrongDeviceNumber: "Podano błędny kod urządzenia, lub urządzenie zostało już wcześniej zarejestrowane.",
    WrongEmailOrAddress: "Nieprawidłowy adres e-mail lub hasło.",
    WrongPassword: "Podano nieprawidłowe hasło",
    WrongPesel: "Nieprawidłowy numer PESEL.",
    WrongSalePoint: "Podano błędny kod sklepu.",
};
